import { InlineStack, Link, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import config, { CHANGELOG_URL, STOREWARE_URL } from "../../config";
import LanguageSwitcher from "./LanguageSwitcher";
import Logo from "./Logo.jsx";

const year = new Date().getFullYear();

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <Link url="/">
        <Logo
          width={150}
          height={38}
        />
      </Link>
      <InlineStack
        gap="200"
        align="center"
        blockAlign="center"
      >
        <Text
          as={"span"}
          tone={"subdued"}
        >
          {t("Copyright")} © {year}
        </Text>
        <Text
          as={"strong"}
          tone={"subdued"}
        >
          |
        </Text>
        <Link
          removeUnderline
          monochrome
          url={STOREWARE_URL}
          target={"_blank"}
        >
          <Text
            as={"strong"}
            tone={"subdued"}
          >
            Storeware
          </Text>
        </Link>
        <Text
          as={"strong"}
          tone={"subdued"}
        >
          |
        </Text>
        <Text
          as={"strong"}
          tone={"subdued"}
        >
          {t("Version")}:{" "}
          <Link
            removeUnderline
            monochrome
            url={CHANGELOG_URL}
            target={"_blank"}
          >
            {config.appVersion}
          </Link>
        </Text>
        |
        <LanguageSwitcher buttonVariant="monochromePlain" />
      </InlineStack>
      <div></div>
    </footer>
  );
};

export default Footer;
